import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../translations/main-translations";

interface CalculationTableOutofBoundsCellProps {
    data: any;
}

const CalculationTableOutofBoundsCell = ({ data }: CalculationTableOutofBoundsCellProps) => {
    const { t } = useTranslation();

    const hasValue = data.value !== undefined;

    return (
        <Tooltip
            title={hasValue ? t(`${translationKeys.VDLANG_CALCULATION_TABLE_TOOLTIP}.baseline_out_of_bounds`) : ""}
            // Disable transition, which causes flickering when moving mouse during transition
            TransitionProps={{ timeout: 0 }}
        >
            <Typography color={hasValue ? "error" : undefined}>{data.text}</Typography>
        </Tooltip>
    );
};

export default React.memo(CalculationTableOutofBoundsCell);

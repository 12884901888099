import { styled } from "@mui/material";
import {
    CalculationEffectListDto,
    CalculationEffectType,
    EffectCategoryAttributeDto,
    EffectCategoryDto,
    EffectDeleteDto,
    EffectField,
    EffectFilterCurrencyField,
    EffectUpdateDto,
    FeatureFlags,
    GlobalCalculationIdentifier,
    MeasureCalculationGranularity,
    MeasureDto,
    SpanEffectListDto,
    areBaselineEffectsValid,
    buildEffectKey,
    calendarMonthToNumber,
} from "api-shared";
import classNames from "classnames";
import type { IDataGridOptions } from "devextreme-react/data-grid";
import { loadMessages, locale } from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
import { TFunction } from "i18next";
import { minBy } from "lodash";
import momentTimezone, { Moment } from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import ConfirmDialog from "../../../components/dialogues/ConfirmDialog";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useClientHasFeature } from "../../../domain/client";
import { useDefaultCurrency } from "../../../domain/currencies";
import { IUpdateSpanEffectInput } from "../../../domain/span-effect";
import useCurrency from "../../../hooks/useCurrency";
import useFocusedTableCell from "../../../hooks/useFocusedTableCell";
import useLazyModule from "../../../hooks/useLazyModule";
import { compareFiscalMoments, formatFiscalUnit } from "../../../lib/fiscal-units";
import { Language, translationKeys } from "../../../translations/main-translations";
import { CellContextProvider } from "./CalculationTableCellContext";
import CalculationTableLabelCell from "./CalculationTableLabelCell";
import CalculationTableLockedCell from "./CalculationTableLockedCell";
import CalculationTableOutOfBoundsCell from "./CalculationTableOutOfBoundsCell";
import EffectCategoryHeaderCell from "./EffectCategoryHeaderCell";
import LevelHeaderCell from "./LevelHeaderCell";
import { addFiscalYearHightlightClass, classLastOfFiscalYear } from "./addFiscalYearHightlightClass";
import useCalculationTableColumns, { EffectColumnIdentifier, VisibleCalculationColumnsMap } from "./useCalculationTableColumns";
import useCalculationTableData, { IEffectRow } from "./useCalculationTableData";
import useSignedEffects from "./useSignedEffects";

const DURATION_FOR_GRANULARITY = {
    [MeasureCalculationGranularity.FISCAL_QUARTER]: 3,
    [MeasureCalculationGranularity.FISCAL_YEAR]: 12,
    [MeasureCalculationGranularity.MONTH]: 1,
};

// Make sure editing effects will be shown in same granularity as persisted (4 decimal digits)
// Although the values stored by the backend have 4 digits precision, marshalling in JSON may result in numbers
// with more digits due to floating point precision
const EFFECT_CELL_EDITOR_OPTIONS = { format: "#,##0.####", step: 0 };

// EffectCategoryColumns should not be smaller than this width
// minWidth can only be given to non-band columns, so this has to be passed to the columns hook
const COLUMN_MIN_WIDTH = 164;

// devexpress renders two tables above each other, so divider with opacity would add up in some places
// This is paper background combined with divider color
const SOLID_DIVIDER_COLOR = "#e0e0e0";

const classes = {
    editorFocusOverlay: "VdCalculationTable-editorFocusOverlay",
    withFiscalYearHightlight: "VdCalculationTable-withFiscalYearHightlight",
    clickableCell: "VdCalculationTable-clickableCell",
    effectColumn: "VdCalculationTable-effectColumn",
    labelColumn: "VdCalculationTable-labelColumn",
    sumColumn: "VdCalculationTable-sumColumn",
    firstOfCategory: "VdCalculationTable-firstOfCategory",
    lastOfCategory: "VdCalculationTable-lastOfCategory",
    lastOfCalculationIdentifier: "VdCalculationTable-lastOfCalculationIdentifier",
    emptyCell: "VdCalculationTable-emptyCell",
    menuCell: "VdCalculationTable-menuCell",
    disabled: "VdCalculationTable-disabled",
};

// Some styles of the grid use lots of :not() selectors onto the table cells, which make it difficult to override those stylings. In those
// cases, !important is used to apply custom styles
const Root = styled("div")(({ theme }) => ({
    "& .dx-datagrid": {
        "& .dx-column-lines": {
            [`& > .${classes.labelColumn}.dx-col-fixed, & > .${classes.labelColumn}, & > .${classes.sumColumn}`]: {
                backgroundColor: theme.palette.background.default,
            },
            // styles applied to table header and table body
            [`& > .${classes.effectColumn}`]: {
                [`&.${classes.firstOfCategory}`]: {
                    borderLeft: `2px solid ${SOLID_DIVIDER_COLOR}`,
                },
                [`&.${classes.lastOfCategory}`]: {
                    borderRight: `2px solid ${SOLID_DIVIDER_COLOR}`,
                },
                [`&.${classes.lastOfCalculationIdentifier}:not(.${classes.lastOfCategory})`]: {
                    borderRight: `1px solid ${SOLID_DIVIDER_COLOR}`,
                },
                // hide column lines in header & body for non-effect columns
                borderLeft: "none",
                borderRight: "none",
            },
        },
        // header and footer styles
        "& .dx-datagrid-headers.dx-header-multi-row, & .dx-datagrid-total-footer": {
            "& .dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed .dx-row > td": {
                [`&.${classes.labelColumn}, &.${classes.sumColumn}`]: {
                    backgroundColor: theme.palette.background.default,
                },
                [`&.${classes.emptyCell}`]: {
                    borderTop: "none",
                    borderBottom: "none",
                },
                [`&.${classes.firstOfCategory}`]: {
                    borderLeft: `2px solid ${SOLID_DIVIDER_COLOR}`,
                },
                [`&.${classes.lastOfCategory}, &.${classes.labelColumn}.dx-col-fixed, &.${classes.labelColumn}`]: {
                    borderRight: `2px solid ${SOLID_DIVIDER_COLOR}`,
                },
                [`&.${classes.lastOfCalculationIdentifier}:not(.${classes.lastOfCategory})`]: {
                    borderRight: `1px solid ${SOLID_DIVIDER_COLOR}`,
                },
                borderRight: "none",
                "&, & > .dx-datagrid-summary-item": {
                    ...theme.typography.body2,
                    verticalAlign: "top",
                    color: theme.palette.text.primary,
                },
                "&, &:last-of-type, &:first-of-type": {
                    // override also special styles for first/last column
                    padding: theme.spacing(0.75, 1),
                },
                [`&.${classes.menuCell}`]: {
                    padding: 0,
                    "& .dx-datagrid-text-content": {
                        // allow MenuCells to span full width of the cell, so menu can be aligned on end of cell
                        width: "100%",
                    },
                },
                // disable hover effect on all header cells
                "&:hover": {
                    backgroundColor: `transparent !important`,
                },
                [`&.${classes.labelColumn}:hover, &.${classes.sumColumn}:hover`]: {
                    backgroundColor: `${theme.palette.background.default} !important`,
                },
            },
        },
        "& .dx-datagrid-rowsview ": {
            "& .dx-datagrid-content .dx-datagrid-table .dx-row.dx-data-row": {
                borderTop: "none",
                borderBottom: "none",
            },
            // styles applied to the table body
            "& .dx-datagrid-content .dx-datagrid-table .dx-row.dx-data-row > td": {
                "&, &:last-of-type, &:first-of-type": {
                    // override also special styles for first/last column
                    padding: theme.spacing(0.75, 1),
                },
                color: theme.palette.text.secondary,
                [`&.${classes.labelColumn}:not(.${classes.disabled})`]: {
                    padding: 0,
                },
                [`& strong, &.${classes.labelColumn}, &.${classes.sumColumn}`]: {
                    ...theme.typography.body2,
                    color: theme.palette.text.primary,
                },
                [`&.${classes.clickableCell}:hover:not(.dx-editor-cell)`]: {
                    backgroundColor: theme.palette.action.hover,
                    borderRadius: theme.shape.borderRadius,
                    cursor: "pointer",
                },
                [`&.${classes.effectColumn}.${classes.disabled}`]: {
                    overflow: "visible",
                },
            },
            [`& .dx-datagrid-content .dx-datagrid-table .dx-row.dx-data-row:first-of-type > td.${classes.labelColumn}:not(.${classes.disabled})`]:
                {
                    // always display the border (in cell background color) and change only its color on hover
                    // this way, content movement issues are avoided
                    borderTop: `${theme.palette.background.default} 1px solid`,
                    "&:hover": {
                        borderTopColor: theme.palette.primary.main,
                    },
                },
            [`& .dx-datagrid-content .dx-datagrid-table .dx-row.dx-data-row:nth-last-of-type(2) > td.${classes.labelColumn}:not(.${classes.disabled})`]:
                {
                    // always display the border (in cell background color) and change only its color on hover
                    // this way, content movement issues are avoided
                    borderBottom: `${theme.palette.background.default} 1px solid`,
                    "&:hover": {
                        borderBottomColor: theme.palette.primary.main,
                    },
                },
            "& .dx-editor-cell .dx-texteditor .dx-texteditor-input": {
                ...theme.typography.body2,
                height: "auto", // reset fixed height of 48px
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
            "& .dx-datagrid-focus-overlay": {
                "&::after": {
                    // hide default underline of DX MUI theme
                    display: "none",
                },
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: theme.shape.borderRadius,
            },
            "& .dx-texteditor-input": {
                color: theme.palette.text.secondary,
            },
            "& .dx-datagrid-revert-tooltip": {
                // this would shortly pop up during save operation
                display: "none",
            },
            "& .dx-datagrid-nodata": {
                ...theme.typography.body2,
            },
        },
        [`&.${classes.withFiscalYearHightlight}`]: {
            [`& .${classLastOfFiscalYear}`]: {
                borderBottom: `1px dashed ${theme.palette.divider} !important`,
                "& + tr.dx-row": {
                    borderTop: "none",
                },
            },
        },
        [`& .${classes.editorFocusOverlay}`]: {
            // styles applied to the focusoverlay element, when editing
            boxShadow: theme.shadows[3],
        },
    },
}));

const customizeFallbackText = ({ value, valueText }: any) => (value == null ? "-" : valueText);

interface ICalculationTableProps {
    data: CalculationEffectListDto;
    translate: TFunction;
    effectCategoryAttributes: EffectCategoryAttributeDto[];
    lang: Language;
    measure: MeasureDto;
    granularity: MeasureCalculationGranularity;
    fiscalYearStart: number;
    start: Moment | null;
    end: Moment | null;
    disabled: boolean;
    effectCategories: EffectCategoryDto[];
    spanEffects: SpanEffectListDto;
    copyLevel: (calculationIdentifier: GlobalCalculationIdentifier, effectCategoryId: number) => void;
    updateSpanEffect: (input: IUpdateSpanEffectInput) => void;
    updateEffects: (data: EffectUpdateDto) => void;
    deleteEffects: (data: EffectDeleteDto) => void;
    visibleCalculationIdentifiers: GlobalCalculationIdentifier[];
    onEffectCategoryDelete: (id: number) => void;
    onEffectCategoryEdit: (id: number) => void;
    onAddTimeslice: (location: "top" | "bottom") => void;
    onEffectCategoryHistory: (effectCategoryId: number) => void;
    lockedCalculationIdentifiers: string[];
}

const CalculationTable = ({
    measure,
    lang,
    data,
    translate,
    effectCategoryAttributes,
    granularity,
    fiscalYearStart,
    start,
    end,
    disabled,
    effectCategories,
    spanEffects,
    updateEffects: updateEffectsProps,
    deleteEffects,
    updateSpanEffect,
    visibleCalculationIdentifiers,
    onEffectCategoryDelete,
    onEffectCategoryEdit,
    onAddTimeslice,
    onEffectCategoryHistory,
    lockedCalculationIdentifiers,
    copyLevel,
}: ICalculationTableProps) => {
    const lazyModule = useLazyModule(() => import("../../../infrastructure/bundles/devextreme"));

    const hasPriceHikeFeature = useClientHasFeature(FeatureFlags.FEATURE_EXPECTED_PRICE_INCREASE);

    // this state can optionally enable the price hike column (even if no value exists in any effect) if the user enables it in the table
    // mapping effectCategoryId -> calculationIdentifier -> boolean
    const [priceHikeByColumn, setPriceHikeByColumn] = useState<Record<number, Record<GlobalCalculationIdentifier, boolean>>>({});

    const visibleCalculationColumns = effectCategories.reduce((map, effectCategory) => {
        map[effectCategory.id] = visibleCalculationIdentifiers.reduce(
            (identifierMap, identifier) => {
                const fields = [];
                const calculationEffects = data.filter((effect) => effect.effectCategoryId);
                const spanEffect = spanEffects.find((se) => se.effectCategoryId === effectCategory.id);
                const hasInitial = spanEffect?.[buildEffectKey(identifier, EffectField.HasInitial)] ?? true;

                if (hasInitial) {
                    fields.push(EffectFilterCurrencyField.Initial);
                }

                fields.push(EffectFilterCurrencyField.Effect);

                if (
                    hasPriceHikeFeature &&
                    (calculationEffects.some((effect) => effect[buildEffectKey(identifier, EffectFilterCurrencyField.PriceHike)] != null) ||
                        priceHikeByColumn[effectCategory.id]?.[identifier] === true)
                ) {
                    fields.push(EffectFilterCurrencyField.PriceHike);
                }

                identifierMap[identifier] = fields;

                return identifierMap;
            },
            {} as Record<GlobalCalculationIdentifier, EffectFilterCurrencyField[]>,
        );

        return map;
    }, {} as VisibleCalculationColumnsMap);

    // used for confirm copy modal dialog
    const [copyTarget, setCopyTarget] = useState<{ effectCategoryId: number; calculationIdentifier: GlobalCalculationIdentifier } | null>(
        null,
    );

    // setup table localization
    useEffect(() => {
        loadMessages(deMessages);
        loadMessages(enMessages);
        locale(lang);
    }, [lang]);

    const [signedCalculationEffects, updateSignedCalculationEffects] = useSignedEffects({
        effectCategories,
        calculationEffects: data,
        updateEffects: updateEffectsProps,
    });

    function hasOutofBoundsBaselineEffects(effectCategoryId: number, calculationIdentifier: GlobalCalculationIdentifier): boolean {
        const baselineEffectsOfECCalculation = signedCalculationEffects.filter(
            (effect) =>
                effect.effectCategoryId === effectCategoryId &&
                effect[buildEffectKey(calculationIdentifier, EffectField.EffectType)] === CalculationEffectType.Baseline,
        );
        if (baselineEffectsOfECCalculation.length === 0) {
            return false;
        }
        const firstBaselineCalculationEffect = minBy(baselineEffectsOfECCalculation, calendarMonthToNumber);
        const firstBaselineMonth = momentTimezone.utc(firstBaselineCalculationEffect);
        return !areBaselineEffectsValid(firstBaselineMonth, baselineEffectsOfECCalculation);
    }

    const dataSource = useCalculationTableData({
        calculationEffects: signedCalculationEffects,
        granularity,
        fiscalYearStart,
        start,
        end,
        effectCategories,
        visibleCalculationIdentifiers,
    });

    useEffect(() => {
        const updateHandler = async (fiscalId: string, values: Record<string, number>) => {
            const store = dataSource.store();
            const row = (await store.byKey(fiscalId)) as IEffectRow | undefined;
            if (row === undefined || Object.keys(values).length <= 0) {
                return;
            }
            const { calendarMoment } = row.fiscalMoment;

            Object.entries(values).forEach(([colId, value]) => {
                const { calculationIdentifier, type, effectCategoryId } = EffectColumnIdentifier.fromString(colId);
                updateSignedCalculationEffects({
                    duration: DURATION_FOR_GRANULARITY[granularity],
                    year: calendarMoment.year(),
                    month: calendarMoment.month(),
                    calculationIdentifier,
                    effectCategoryId,
                    type,
                    value,
                });
            });
        };
        // remove all previous handlers and attach the updated one
        dataSource.store().off("updating").on("updating", updateHandler);
    }, [dataSource, granularity, updateSignedCalculationEffects]);

    const updateFocusedCell = useFocusedTableCell();

    const addEditorStylingToFocusOverlay = useCallback<NonNullable<IDataGridOptions["onEditorPrepared"]>>(({ element, editorElement }) => {
        const focusOverlay = element?.querySelector(".dx-datagrid-focus-overlay");
        if (focusOverlay != null && editorElement != null) {
            focusOverlay.classList.add(classes.editorFocusOverlay);
            // remove additional styles from focus-overly element, when loosing focus on the input element
            const removeEditorStyling = () => {
                focusOverlay.classList.remove(classes.editorFocusOverlay);
                editorElement.removeEventListener("focusout", removeEditorStyling);
            };

            // native events here, so blur does not bubble. use focusout instead (which bubbles)
            editorElement.addEventListener("focusout", removeEditorStyling);
        }
    }, []);

    const defaultCurrency = useDefaultCurrency();

    const { effectColumns, categoryColumns, levelColumns, sumColumns, labelColumns, currencyCols } = useCalculationTableColumns({
        measureConfig: measure.measureConfig,
        effectCategoryAttributes,
        effectCategories,
        translate,
        classes,
        visibleCalculationIdentifiers,
        minWidth: COLUMN_MIN_WIDTH,
        summaryCurrency: defaultCurrency,
        lockedCalculationIdentifiers,
        userCanEdit: !disabled,
        visibleCalculationColumns,
    });

    const saveColumns = (
        effectCategoryId: number,
        calculationIdentifier: GlobalCalculationIdentifier,
        newHasInitial: boolean,
        newPriceHike: boolean,
    ) => {
        // price hike column can be optionally enabled in client to edit values, update the local state that holds the information that the column is enabled
        setPriceHikeByColumn((old) =>
            old[effectCategoryId]?.[calculationIdentifier] === newPriceHike
                ? old
                : {
                      ...priceHikeByColumn,
                      [effectCategoryId]: { ...priceHikeByColumn[effectCategoryId], [calculationIdentifier]: newPriceHike },
                  },
        );

        if (!newHasInitial) {
            deleteEffects({ effectCategoryId, calculationIdentifier, type: EffectFilterCurrencyField.Initial });
        }

        const priceHikeKey = buildEffectKey(calculationIdentifier, EffectFilterCurrencyField.PriceHike);
        const hasPriceHikeValues = data.some((item) => item[priceHikeKey] !== null);
        if (hasPriceHikeFeature && (!newPriceHike || !newHasInitial) && hasPriceHikeValues) {
            deleteEffects({ effectCategoryId, calculationIdentifier, type: EffectFilterCurrencyField.PriceHike });
        }

        const spanEffect = spanEffects.find((se) => se.effectCategoryId === effectCategoryId);

        if (spanEffect === undefined) {
            return;
        }

        const oldHasInitial = spanEffect[buildEffectKey(calculationIdentifier, EffectField.HasInitial)];

        if (oldHasInitial !== newHasInitial) {
            updateSpanEffect({
                spanEffectId: spanEffect.id,
                payload: {
                    calculationIdentifier,
                    hasInitial: newHasInitial,
                },
            });
        }
    };

    const getIsFieldVisible = (
        effectCategoryId: number,
        calculationIdentifier: GlobalCalculationIdentifier,
        type: EffectFilterCurrencyField,
    ) => {
        return visibleCalculationColumns[effectCategoryId]?.[calculationIdentifier]?.includes(type) ?? false;
    };

    const confirmCopyLevel = () => {
        if (copyTarget === null) {
            return;
        }

        copyLevel(copyTarget.calculationIdentifier, copyTarget.effectCategoryId);
    };

    const customizeLabelText = useCallback(({ value }: any) => formatFiscalUnit(value, granularity, translate), [granularity, translate]);

    // replace whole table, if count of columns changes to prevent glitchy update.
    const dataGridKey = `${labelColumns.length}_${categoryColumns.length}_${levelColumns.length}_${effectColumns.length}`;

    const { formatCurrency } = useCurrency({ currency: defaultCurrency });

    const customizeTextWithClientCurrency = useCallback(
        ({ value, valueText }: any) => {
            return value == null ? "-" : (formatCurrency(valueText) ?? valueText);
        },
        [formatCurrency],
    );

    if (lazyModule === undefined) {
        return <LoadingAnimation />;
    }

    const { DxDataGrid, Column, Editing, Format, KeyboardNavigation, Paging, Sorting, Summary, Texts, TotalItem, ValueFormat } = lazyModule;
    return (
        <Root
            className={classNames(
                "dx-viewport",
                granularity !== MeasureCalculationGranularity.FISCAL_YEAR && classes.withFiscalYearHightlight,
            )}
        >
            <ConfirmDialog
                open={copyTarget != null}
                onClose={() => setCopyTarget(null)}
                title={translate("Copy")}
                item="calculation_identifier"
                primary={translationKeys.VDLANG_CONFIRM}
                translate={translate}
                onConfirm={confirmCopyLevel}
                primaryDanger
            >
                {translate(translationKeys.VDLANG_CALCULATION_TABLE_COPY_DATA_WARNING)}
            </ConfirmDialog>
            <CellContextProvider
                onEditCategory={onEffectCategoryEdit}
                onRemoveCategory={onEffectCategoryDelete}
                onOpenHistory={onEffectCategoryHistory}
                translate={translate}
                onAddTimeslice={onAddTimeslice}
                onSaveColumns={saveColumns}
                onCopyLevel={(effectCategoryId, calculationIdentifier) => {
                    setCopyTarget({ effectCategoryId, calculationIdentifier });
                }}
                getIsFieldVisible={getIsFieldVisible}
            >
                <DxDataGrid
                    // beware that the Grid behaves glitchy when some of the passed props change, so keep changes as minimal as possible
                    key={dataGridKey}
                    columnAutoWidth
                    repaintChangesOnly
                    wordWrapEnabled
                    showColumnLines
                    showRowLines={false}
                    dataSource={dataSource}
                    onFocusedCellChanging={updateFocusedCell}
                    onEditorPrepared={addEditorStylingToFocusOverlay}
                    onRowPrepared={addFiscalYearHightlightClass}
                    noDataText={translate(translationKeys.VDLANG_MEASURE_CALCULATION_TABLE_NO_DATA)}
                >
                    {labelColumns.map(({ allowEditing, ...headerLabelColumn }) => (
                        <Column
                            key={headerLabelColumn.name}
                            fixed
                            alignment={headerLabelColumn.isBand ? "left" : "right"}
                            allowEditing={false}
                            cellComponent={allowEditing !== false ? CalculationTableLabelCell : undefined}
                            customizeText={!headerLabelColumn.isBand ? customizeLabelText : undefined}
                            {...headerLabelColumn}
                            cssClass={classNames(
                                headerLabelColumn.cssClass,
                                classes.labelColumn,
                                allowEditing !== undefined && allowEditing !== false && classes.disabled,
                            )}
                        />
                    ))}

                    {categoryColumns.map((column) => (
                        <Column
                            key={column.name}
                            {...column}
                            cssClass={classNames(column.cssClass, classes.menuCell)}
                            alignment="center"
                            headerCellComponent={EffectCategoryHeaderCell}
                        />
                    ))}

                    {currencyCols.map((column) => (
                        <Column
                            key={column.name}
                            {...column}
                            cssClass={classNames(column.cssClass, classes.menuCell)}
                            alignment="center"
                            headerCellComponent={EffectCategoryHeaderCell}
                        />
                    ))}

                    {levelColumns.map(({ allowEditing, ...levelColumn }) => (
                        <Column
                            key={levelColumn.name}
                            {...levelColumn}
                            cssClass={classNames(levelColumn.cssClass, allowEditing !== false && classes.menuCell)}
                            alignment="center"
                            headerCellComponent={allowEditing !== false ? LevelHeaderCell : undefined}
                        />
                    ))}
                    {effectColumns.map(({ cssClass, calculationIdentifier, effectCategoryId, ...effectColumn }) => {
                        const isOutOfBounds = hasOutofBoundsBaselineEffects(effectCategoryId, calculationIdentifier);
                        let cellComponent;
                        if (isOutOfBounds) {
                            cellComponent = CalculationTableOutOfBoundsCell;
                        }
                        if (effectColumn.isCalculationLocked) {
                            cellComponent = CalculationTableLockedCell;
                        }
                        return (
                            <Column
                                key={effectColumn.name}
                                dataType="number"
                                cssClass={classNames(cssClass, classes.effectColumn, {
                                    [classes.clickableCell]: effectColumn.allowEditing !== false,
                                    [classes.disabled]: effectColumn.allowEditing === false,
                                })}
                                customizeText={customizeFallbackText}
                                editorOptions={EFFECT_CELL_EDITOR_OPTIONS}
                                cellComponent={cellComponent}
                                {...effectColumn}
                            >
                                <Format type="fixedPoint" precision={2} />
                            </Column>
                        );
                    })}
                    {sumColumns.map(({ cssClass, ...sumColumn }) => (
                        <Column
                            key={sumColumn.name}
                            dataType="number"
                            allowEditing={false}
                            alignment={sumColumn.isBand ? "left" : "right"}
                            customizeText={customizeTextWithClientCurrency}
                            cssClass={classNames(cssClass, classes.sumColumn, classes.lastOfCalculationIdentifier)}
                            {...sumColumn}
                        />
                    ))}
                    {/* Invisible column for sorting */}
                    <Column visible={false} dataField="fiscalMoment" sortingMethod={compareFiscalMoments} sortIndex={0} sortOrder="asc" />
                    <Summary>
                        {labelColumns
                            .filter((c) => !c.isBand)
                            .map((c) => (
                                <TotalItem key={c.name} alignment="left" column={c.name} displayFormat={translate("sum")} />
                            ))}
                        {effectColumns.map(({ name }) => (
                            <TotalItem
                                key={name}
                                skipEmptyValues
                                column={name}
                                summaryType="sum"
                                customizeText={customizeFallbackText}
                                displayFormat="{0}"
                            >
                                <ValueFormat type="fixedPoint" precision={2} />
                            </TotalItem>
                        ))}
                        {sumColumns
                            .filter((c) => !c.isBand)
                            .map(({ name }) => (
                                <TotalItem
                                    key={name}
                                    skipEmptyValues
                                    column={name}
                                    summaryType="sum"
                                    customizeText={customizeTextWithClientCurrency}
                                    displayFormat="{0}"
                                />
                            ))}
                    </Summary>
                    <KeyboardNavigation editOnKeyPress={!disabled} enterKeyAction="moveFocus" enterKeyDirection="column" />
                    <Sorting mode="none" />
                    <Paging enabled={false} />
                    <Editing mode="cell" allowUpdating={!disabled} startEditAction="dblClick" />
                    <Texts />
                </DxDataGrid>
            </CellContextProvider>
        </Root>
    );
};
export default React.memo(CalculationTable);

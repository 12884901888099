import {
    CalculationType,
    CurrencyDto,
    EffectCategoryAttributeDto,
    EffectCategoryDto,
    EffectFilterCurrencyField,
    EffectType,
    GlobalCalculationIdentifier,
    MeasureConfigDto,
    mergeCamelized,
    zNumericId,
} from "api-shared";
import classNames from "classnames";
import type { IColumnProps } from "devextreme-react/data-grid";
import { TFunction } from "i18next";
import { findIndex, findLastIndex, groupBy, sortBy } from "lodash";
import { nativeEnum, z } from "zod";
import { useCurrencies } from "../../../domain/currencies";
import useFieldData from "../../../hooks/useFieldData";
import { TranslatedOption } from "../../../lib/field-options";
import { translationKeys } from "../../../translations/main-translations";

// In devextreme v23, typing was made stricter so that e.g. `ownerBand` must now be a number or undefined
// Hence we must use this transitional interface until we implement a different solution for deriving the final index for `ownerBand`
interface IColumnPropsCompat extends Omit<IColumnProps, "ownerBand"> {
    ownerBand?: string | number;
}

interface IEffectColumnProps extends IColumnProps {
    isCalculationLocked: boolean;
    effectCategoryId: number;
    calculationIdentifier: GlobalCalculationIdentifier;
}

// effectCategoryId -> calculationIdentifier -> visible fields by effectCategoryId and calculationIdentifier
export type VisibleCalculationColumnsMap = Record<number, Record<GlobalCalculationIdentifier, EffectFilterCurrencyField[]>>;

enum ColumnNames {
    EffectCategoryAttributePrefix = "effect_category_attribute",
    EffectCategoryValuePrefix = "effect_category_value",
    EffectCategoryCurrency = "effect_category_currency",

    TotalTop = "total_top",
    TotalCurrency = "total_currency",
    TotalPrefix = "total",
    TotalFiller = "total_filler",
    LabelLevel = "label_level",
    LabelType = "label_typevalue",
}

const zEffectColumnIdentifier = z.object({
    calculationIdentifier: z.nativeEnum(GlobalCalculationIdentifier),
    effectCategoryId: zNumericId,
    type: z.nativeEnum(EffectFilterCurrencyField),
});

export class EffectColumnIdentifier {
    private static readonly PARSE_REGEX = /EC(?<effectCategoryId>\d+)_(?<calculationIdentifier>\w+)_(?<type>\w+)/;

    constructor(
        public readonly calculationIdentifier: GlobalCalculationIdentifier,
        public readonly effectCategoryId: number,
        public readonly type: EffectFilterCurrencyField,
    ) {}

    static fromString(input: string): EffectColumnIdentifier {
        const match = input.match(this.PARSE_REGEX);
        if (match === null || match.groups === undefined) {
            throw new Error(`Parsing of column identifier '${input}' failed`);
        }
        const { effectCategoryId, calculationIdentifier, type } = zEffectColumnIdentifier.parse(match.groups);
        return new EffectColumnIdentifier(calculationIdentifier, effectCategoryId, type);
    }

    toString(): string {
        return `EC${this.effectCategoryId}_${this.calculationIdentifier}_${this.type}`;
    }
}

const getFieldLabel = (fieldName: string, translate: TFunction, processName: string) => {
    const keys = [mergeCamelized(fieldName, processName), fieldName];
    return translate(keys);
};

function getLabelColumns(
    categoryFields: EffectCategoryAttributeDto[],
    minWidth: number,
    translate: TFunction,
    processName: string,
    emptyCellClass: string,
): IColumnPropsCompat[] {
    const fieldColumns = categoryFields.map(({ title }, index, arr) => ({
        name: `${ColumnNames.EffectCategoryAttributePrefix}_${title}`,
        caption: getFieldLabel(title, translate, processName),
        ownerBand: index > 0 ? `${ColumnNames.EffectCategoryAttributePrefix}_${arr[index - 1].title}` : undefined,
        isBand: true,
    }));

    const currencyColumn = {
        name: ColumnNames.EffectCategoryCurrency,
        caption: translate("Currency"),
        ownerBand: fieldColumns[fieldColumns.length - 1]?.name,
        isBand: true,
    };

    const levelColumn = {
        name: ColumnNames.LabelLevel,
        caption: "",
        isBand: true,
        ownerBand: currencyColumn.name,
        cssClass: emptyCellClass,
    };

    const lowestColumn = {
        name: ColumnNames.LabelType,
        dataField: "fiscalMoment",
        caption: translate(translationKeys.VDLANG_EFFECT_FIELD_LABEL_COLUMN_HEADER),
        ownerBand: levelColumn.name,
        minWidth,
    };
    return [...fieldColumns, currencyColumn, levelColumn, lowestColumn];
}

function getEffectCategoryLevelColumns(
    columnIdentifiersByCategory: Record<number, EffectColumnIdentifier[]>,
    effectCategories: EffectCategoryDto[],
    translate: TFunction,
    firstOfCategoryClass: string,
    lastOfCategoryClass: string,
    lockedCalculationIdentifiers: string[],
    userCanEdit: boolean,
): IColumnPropsCompat[] {
    return Object.entries(columnIdentifiersByCategory).flatMap(([categoryId, identifiers]) => {
        const columnIdentifiersByCalculationIdentifier = new Set(identifiers.map((identifier) => identifier.calculationIdentifier));

        const levelColumns = [...columnIdentifiersByCalculationIdentifier]
            .map((id) => nativeEnum(GlobalCalculationIdentifier).parse(id))
            .map((calculationIdentifier) => {
                const effectCategory = effectCategories.find((ec) => ec.id === Number(categoryId));
                const calculationType = effectCategory?.calculationTypes[calculationIdentifier] ?? CalculationType.NonLinear;
                const isNonLinear = calculationType === CalculationType.NonLinear;

                return {
                    calculationIdentifier,
                    effectCategoryId: Number(categoryId),
                    name: `${ColumnNames.LabelLevel}_${categoryId}_${calculationIdentifier}`,
                    caption: translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${calculationIdentifier}`),
                    ownerBand: `${ColumnNames.EffectCategoryCurrency}_${categoryId}`,
                    isNonLinear: isNonLinear,
                    isBand: true,
                    allowEditing: userCanEdit && !lockedCalculationIdentifiers.includes(calculationIdentifier ?? ""),
                } as IColumnPropsCompat;
            });

        // add attributes that helps to determine if copy button can be shown
        // ColumnProps come from devexpress and cannot be extended, so use any here
        levelColumns.forEach((orderedTypeColumn: any, index) => {
            orderedTypeColumn.copyEnabled = index > 0;
            if (index > 0) {
                orderedTypeColumn.prevCaption = levelColumns[index - 1].caption;
            }
        });
        levelColumns[0].cssClass = firstOfCategoryClass;
        levelColumns[levelColumns.length - 1].cssClass = lastOfCategoryClass;
        return levelColumns;
    });
}

// eslint-disable-next-line max-params
function getEffectCategoryValueColumns(
    effectCategories: EffectCategoryDto[],
    columnIdentifiersByCategory: Record<number, EffectColumnIdentifier[]>,
    sortedCategoryFields: EffectCategoryAttributeDto[],
    fieldData: TranslatedOption[][],
    firstOfCategoryClass: string,
    lastOfCategoryClass: string,
    lockedCalculationIdentifiers: string[],
    userCanEdit: boolean,
): IColumnPropsCompat[] {
    return effectCategories
        .filter((ec) => columnIdentifiersByCategory[ec.id] != null)
        .flatMap(({ id: ecId, effectCategoryValues }) => {
            const orderedValues = sortBy(effectCategoryValues, (value) =>
                sortedCategoryFields.findIndex(({ id }) => id === value.effectCategoryAttributeId),
            );
            return orderedValues.map((ecv, index, arr) => ({
                name: index < arr.length - 1 ? `${ColumnNames.EffectCategoryValuePrefix}_${ecv.id}` : `EC${ecId}_last_value`,
                ownerBand: index > 0 ? `${ColumnNames.EffectCategoryValuePrefix}_${arr[index - 1].id}` : undefined,
                caption: fieldData[index].find((option) => option.id === ecv.value)?.name,
                cssClass: classNames(firstOfCategoryClass, lastOfCategoryClass),
                isBand: true,
                effectCategoryId: ecId,
                allowEditing: userCanEdit && lockedCalculationIdentifiers.length === 0,
            }));
        });
}

function getCurrencyColumns(
    effectCategories: EffectCategoryDto[],
    lastOfCategoryClass: string,
    lockedCalculationIdentifiers: string[],
    userCanEdit: boolean,
    currencies: CurrencyDto[],
): IColumnPropsCompat[] {
    return effectCategories.map((ec) => ({
        name: `${ColumnNames.EffectCategoryCurrency}_${ec.id}`,
        caption: currencies.find((currency) => currency.id === ec.currencyId)?.isoCode,
        isBand: true,
        ownerBand: `EC${ec.id}_last_value`,
        cssClass: lastOfCategoryClass,
        allowEditing: userCanEdit && lockedCalculationIdentifiers.length === 0,
    }));
}

function getEffectColumnIdentifiers(
    effectCategories: EffectCategoryDto[],
    visibleCalculationIdentifiers: GlobalCalculationIdentifier[],
    visibleCalculationColumns: VisibleCalculationColumnsMap,
): EffectColumnIdentifier[] {
    const identifiers: EffectColumnIdentifier[] = [];

    effectCategories.forEach((ec) => {
        visibleCalculationIdentifiers.forEach((identifier) => {
            const fieldList = visibleCalculationColumns[ec.id]?.[identifier] ?? [];

            fieldList.forEach((field) => {
                identifiers.push(new EffectColumnIdentifier(identifier, ec.id, field));
            });
        });
    });

    return identifiers;
}

function getEffectColumns(
    columnIdentifiers: EffectColumnIdentifier[],
    effectCategories: EffectCategoryDto[],
    minWidth: number,
    translate: TFunction,
    classes: Record<"firstOfCategory" | "lastOfCategory" | "lastOfCalculationIdentifier", string>,
    lockedCalculationIdentifiers: string[],
    userCanEdit: boolean,
): IColumnPropsCompat[] {
    return columnIdentifiers.map((identifier, index, arr) => {
        const effectType = effectCategories.find(({ id }) => id === identifier.effectCategoryId)?.effectType;
        const captionKey =
            identifier.type === EffectFilterCurrencyField.Effect ? `effect_type_${effectType ?? EffectType.Savings}` : identifier.type;

        const firstOfCategoryIndex = findIndex(columnIdentifiers, { effectCategoryId: identifier.effectCategoryId });
        const lastOfCategoryIndex = findLastIndex(columnIdentifiers, { effectCategoryId: identifier.effectCategoryId });
        const categoryColumnCount = lastOfCategoryIndex - firstOfCategoryIndex + 1;
        const isCalculationLocked = lockedCalculationIdentifiers.includes(identifier.calculationIdentifier ?? "");

        return {
            name: identifier.toString(),
            effectCategoryId: identifier.effectCategoryId,
            calculationIdentifier: identifier.calculationIdentifier,
            dataField: identifier.toString(),
            caption: translate(captionKey),
            ownerBand: `${ColumnNames.LabelLevel}_${identifier.effectCategoryId}_${identifier.calculationIdentifier}`,
            minWidth: minWidth / categoryColumnCount, // make sure that category columns have given min-width
            cssClass: classNames({
                [classes.firstOfCategory]: firstOfCategoryIndex === index,
                [classes.lastOfCategory]: lastOfCategoryIndex === index,
                [classes.lastOfCalculationIdentifier]: arr[index + 1]?.calculationIdentifier !== identifier.calculationIdentifier,
            }),
            allowEditing: userCanEdit && !isCalculationLocked,
            isCalculationLocked,
        } as IColumnPropsCompat;
    });
}

function getSumColumns(
    visibleCalculationIdentifiers: string[],
    translate: TFunction,
    fieldCount: number,
    emptyCellClass: string,
    currency: CurrencyDto,
): IColumnPropsCompat[] {
    // Filler columns are added for fieldCount > 1. Css styling and parent child behavior of the other
    // columns differ based on the presence of these filler columns.
    const hasFillerColumns = fieldCount > 1;

    const topColumn = {
        name: ColumnNames.TotalTop,
        isBand: true,
        caption: translate(translationKeys.VDLANG_MEASURE_CALCULATION_TOTAL_COLUMN_HEADER),
        cssClass: hasFillerColumns ? emptyCellClass : undefined,
    };

    // Add some (empty) cells on top to ensure proper alignment of bottom rows with dynamic amount of effectCategoryFields
    const fillerColumns = [...Array(fieldCount - 1)].map((_, i, arr) => ({
        caption: "",
        name: `${ColumnNames.TotalFiller}_${i}`,
        ownerBand: i > 0 ? `${ColumnNames.TotalFiller}_${i - 1}` : topColumn.name,
        cssClass: i < arr.length - 1 ? emptyCellClass : undefined, // do not apply empty cell styles on the last filler cell
        isBand: true,
    }));

    const currencyColumn = {
        name: ColumnNames.TotalCurrency,
        ownerBand: hasFillerColumns ? fillerColumns[fillerColumns.length - 1]?.name : topColumn.name,
        isBand: true,
        caption: currency.isoCode,
    };

    const levelColumns = visibleCalculationIdentifiers.flatMap((calculationIdentifier) => [
        {
            // calculation identifier
            name: `${ColumnNames.TotalPrefix}_${calculationIdentifier}`,
            caption: translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${calculationIdentifier}`),
            isBand: true,
            ownerBand: currencyColumn.name,
        },
        {
            // below calculation identifier
            caption: translate(EffectFilterCurrencyField.Effect),
            name: `${ColumnNames.TotalPrefix}_${calculationIdentifier}_${EffectFilterCurrencyField.Effect}`,
            dataField: `${ColumnNames.TotalPrefix}_${calculationIdentifier}_${EffectFilterCurrencyField.Effect}`,
            ownerBand: `${ColumnNames.TotalPrefix}_${calculationIdentifier}`,
        },
    ]);

    return [topColumn, currencyColumn, ...fillerColumns, ...levelColumns];
}

function customizeColumns(columns: IColumnPropsCompat[]): void {
    // in-place edit the provided column array
    columns.forEach((column) => {
        // ownerBand needs to be the index of the parent column, but is provided as string (the columns name)
        // resolve the parent column name to its final index here
        if (typeof column.ownerBand !== "string") {
            return column;
        }
        const parentColumn = columns.findIndex(({ name }) => name === column.ownerBand);
        if (parentColumn > -1) {
            column.ownerBand = parentColumn;
        }
    });
}

interface IUseCalculationTableColumnsProps {
    translate: TFunction;
    measureConfig: MeasureConfigDto;
    effectCategoryAttributes: EffectCategoryAttributeDto[];
    effectCategories: EffectCategoryDto[];
    classes: Record<"firstOfCategory" | "lastOfCategory" | "lastOfCalculationIdentifier" | "emptyCell", string>;
    minWidth: number;
    visibleCalculationIdentifiers: GlobalCalculationIdentifier[];
    summaryCurrency: CurrencyDto;
    lockedCalculationIdentifiers: string[];
    userCanEdit: boolean;
    visibleCalculationColumns: VisibleCalculationColumnsMap;
}

const useCalculationTableColumns = ({
    measureConfig,
    effectCategoryAttributes,
    effectCategories,
    translate,
    classes,
    visibleCalculationIdentifiers,
    minWidth,
    summaryCurrency,
    lockedCalculationIdentifiers,
    userCanEdit,
    visibleCalculationColumns,
}: IUseCalculationTableColumnsProps) => {
    // Don't use measureConfig.effectCategoryAttributes here
    const { name: processName } = measureConfig;

    const effectColumnIdentifiers = getEffectColumnIdentifiers(effectCategories, visibleCalculationIdentifiers, visibleCalculationColumns);
    const effectColumnIdentifiersByCategory = groupBy(effectColumnIdentifiers, (identifier) => identifier.effectCategoryId);

    // Columns for EffectCategories
    const sortedCategoryFields = [...effectCategoryAttributes].sort((a, b) =>
        a.order !== null && b.order !== null ? a.order - b.order : 0,
    );
    const fieldData = useFieldData(sortedCategoryFields);
    const categoryColumns = getEffectCategoryValueColumns(
        effectCategories,
        effectColumnIdentifiersByCategory,
        sortedCategoryFields,
        fieldData,
        classes.firstOfCategory,
        classes.lastOfCategory,
        lockedCalculationIdentifiers,
        userCanEdit,
    );

    const currencies = useCurrencies();

    const currencyColumns = getCurrencyColumns(
        effectCategories,
        classes.lastOfCategory,
        lockedCalculationIdentifiers,
        userCanEdit,
        currencies,
    );

    const levelColumns = getEffectCategoryLevelColumns(
        effectColumnIdentifiersByCategory,
        effectCategories,
        translate,
        classes.firstOfCategory,
        classes.lastOfCategory,
        lockedCalculationIdentifiers,
        userCanEdit,
    );

    const effectColumns = getEffectColumns(
        effectColumnIdentifiers,
        effectCategories,
        minWidth,
        translate,
        classes,
        lockedCalculationIdentifiers,
        userCanEdit,
    );

    // Other columns
    const sumColumns = getSumColumns(
        visibleCalculationIdentifiers,
        translate,
        effectCategoryAttributes.length,
        classes.emptyCell,
        summaryCurrency,
    );

    const labelColumns = getLabelColumns(sortedCategoryFields, minWidth, translate, processName, classes.emptyCell);

    // make assumption about column ordering here
    // It enables passing the correct column definitions to the table and avoids a "second rendering pass" using
    // customizeColumns callback
    const orderedColumns = [...labelColumns, ...categoryColumns, ...currencyColumns, ...levelColumns, ...effectColumns, ...sumColumns];

    customizeColumns(orderedColumns);

    return {
        effectColumns: effectColumns as IEffectColumnProps[],
        categoryColumns: categoryColumns as IColumnProps[],
        levelColumns: levelColumns as IColumnProps[],
        sumColumns: sumColumns as IColumnProps[],
        labelColumns: labelColumns as IColumnProps[],
        currencyCols: currencyColumns as IColumnProps[],
    };
};

export default useCalculationTableColumns;

import { validateCurrencyValue } from "api-shared";
import type { IDataGridOptions } from "devextreme-react/data-grid";
import type dxDataGrid from "devextreme/ui/data_grid";
import { useCallback, useRef } from "react";

interface FocusedCell {
    rowKey: string;
    value: number | undefined;
    dataField: string;
    component: dxDataGrid<unknown, string>;
}

// As the newer property userAgentData is not yet available on all browsers, we keep this deprecated access via navigator.platform for now
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData#browser_compatibility
// eslint-disable-next-line @typescript-eslint/no-deprecated
const IS_MAC = navigator.platform.indexOf("Mac") >= 0;

function parseClipboardValue(value?: string): number | null | undefined {
    if (value === undefined) {
        return undefined;
    }

    // Empty strings clear the effect cell
    if (value === "") {
        return null;
    }

    let parsedValue;
    try {
        parsedValue = JSON.parse(value);
    } catch {
        return undefined;
    }

    // Has to be a number to be pasted into an effect cell
    if (typeof parsedValue !== "number") {
        return undefined;
    }

    // Has to be a valid currency value to be pasted into an effect cell
    if (!validateCurrencyValue(parsedValue)) {
        return undefined;
    }

    return parsedValue;
}

function parseCellValue(value?: number | null): string {
    if (value === undefined || value === null) {
        return "";
    }

    // Cut off to at most 4 precision digits
    const rounded = Math.floor(value * 10000) / 10000;
    return JSON.stringify(rounded);
}

function saveValue(focusedCell: FocusedCell, pastedValue: any): void {
    const { rowKey, dataField, component } = focusedCell;
    const store = component.getDataSource().store();
    const changes = { [dataField]: pastedValue };

    // request an update from the store -> will not notify the DataSource and Grid about the changes
    // but will send changes to API
    store.update(rowKey, changes);

    // To show new value early (before API response), also notifiy DataSource/Grid about the changes
    store.push([{ type: "update", key: rowKey, data: changes }]);
}

async function getClipboardValue(event?: ClipboardEvent): Promise<string | undefined> {
    if (event?.clipboardData) {
        return event.clipboardData.getData("text/plain");
    }

    // Firefox cannot read from the clipboard
    // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/readText#browser_compatibility
    if (navigator.clipboard?.readText !== undefined) {
        return navigator.clipboard.readText().catch(() => undefined);
    }

    // eslint-disable-next-line no-console
    console.warn("Giving up, no clipboard access available", event, navigator.clipboard);
    return undefined;
}

function useFocusedTableCell() {
    // useRef does not trigger a re-render (as useState does). This is important to keep the returned function reference stable,
    // so that the table is not fully re-rendered on every focus event. This may reset some of the table state or change dom element
    // references
    const focusedCell = useRef<FocusedCell>();
    const focusedElement = useRef<HTMLElement>();

    const copyEventHandler = useCallback((event: ClipboardEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        if (focusedCell.current == null) {
            // nothing to do in case we do not have the focused cell
            // also do not prevent default action
            return;
        }

        if (event.clipboardData?.setData != null) {
            const clipboardValue = parseCellValue(focusedCell.current.value);
            event.clipboardData?.setData("text/plain", clipboardValue);
        }
        // block default copy action, which would overwrite custom clipboard data
        // block it only when our custom data has been copied to clipboard, so default copy will work in other cases (e.g. IE11)
        event.preventDefault();
    }, []);

    const pasteEventHandler = useCallback((event: ClipboardEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        void getClipboardValue(event).then((rawPastedValue) => {
            if (focusedCell.current == null) {
                // nothing to do in case we do not have the focused cell
                // also do not prevent default action
                return;
            }

            const pastedValue = parseClipboardValue(rawPastedValue);
            pastedValue !== undefined && saveValue(focusedCell.current, pastedValue);

            return event.preventDefault();
        });
    }, []);

    const copyCellValueKeyHandler = useCallback((event: KeyboardEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        if (!(event.ctrlKey || event.metaKey)) {
            return;
        }

        if (event.key !== "c") {
            return;
        }

        if (focusedCell.current == null) {
            // nothing to do in case we do not have the focused cell
            // also do not prevent default action
            return;
        }

        const clipboardValue = parseCellValue(focusedCell.current.value);
        if (!navigator.clipboard) {
            // eslint-disable-next-line no-console
            console.warn("Clipboard API not available. Check window.isSecureContext if it is disabled because of an insecure connection");
            return;
        }

        // prevent editing cell
        event.stopPropagation();

        void navigator.clipboard
            .writeText(clipboardValue)
            .catch(() => {
                // clipboard is not available
            })
            // do not start editing cell
            .then(() => event.preventDefault());
    }, []);

    const pasteCellValueKeyHandler = useCallback((event: KeyboardEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        if (!(event.ctrlKey || event.metaKey)) {
            return;
        }

        if (event.key !== "v") {
            return;
        }

        if (focusedCell.current == null) {
            return;
        }

        if (!navigator.clipboard) {
            // eslint-disable-next-line no-console
            console.warn("Clipboard API not available. Check if window.isSecureContext is disabled because of an insecure connection");
            return;
        }

        // prevent editing cell
        event.stopPropagation();

        void getClipboardValue().then((rawPastedValue) => {
            const pastedValue = parseClipboardValue(rawPastedValue);
            pastedValue !== undefined && focusedCell.current != null && saveValue(focusedCell.current, pastedValue);

            // do not start editing cell
            return event.preventDefault();
        });
    }, []);

    const deleteCellValueKeyHandler = useCallback((event: KeyboardEvent) => {
        if (event.defaultPrevented || event.key !== "Delete" || focusedCell.current == null) {
            return;
        }

        // prevent editing cell
        event.stopPropagation();

        saveValue(focusedCell.current, null);

        // do not start editing cell
        event.preventDefault();
    }, []);

    const setFocusedCell = useCallback(
        (cell: FocusedCell, element: HTMLElement) => {
            if (focusedElement.current != null) {
                // element changed focused, remove handlers from previous element
                if (IS_MAC) {
                    focusedElement.current.removeEventListener("keydown", copyCellValueKeyHandler);
                    focusedElement.current.removeEventListener("keydown", pasteCellValueKeyHandler);
                } else {
                    focusedElement.current.removeEventListener("copy", copyEventHandler);
                    focusedElement.current.removeEventListener("paste", pasteEventHandler);
                }

                focusedElement.current.removeEventListener("keydown", deleteCellValueKeyHandler);
            }

            if (element != null) {
                // add handlers to new element
                if (IS_MAC) {
                    element.addEventListener("keydown", copyCellValueKeyHandler);
                    element.addEventListener("keydown", pasteCellValueKeyHandler);
                } else {
                    element.addEventListener("copy", copyEventHandler);
                    element.addEventListener("paste", pasteEventHandler);
                }

                element.addEventListener("keydown", deleteCellValueKeyHandler);
            }

            focusedCell.current = cell;
            focusedElement.current = element;
        },
        [copyCellValueKeyHandler, pasteCellValueKeyHandler, deleteCellValueKeyHandler, copyEventHandler, pasteEventHandler],
    );

    // Assigning the callback to a named variable eases debugging.
    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const updateFocusedCell = useCallback<NonNullable<IDataGridOptions["onFocusedCellChanging"]>>(
        (event) => {
            const { columns, newColumnIndex, rows, newRowIndex, cellElement, component } = event;
            if (columns == null || rows == null || newColumnIndex == null || newRowIndex == null || component == null) {
                return;
            }

            const column = columns[newColumnIndex];
            const row = rows[newRowIndex];
            if (column == null || row == null || row.values == null) {
                return;
            }

            const { dataField, allowEditing } = column;

            if (!allowEditing) {
                // do not allow focusing of non-editable cells
                event.cancel = true;
                event.isHighlighted = false;
                return;
            }

            event.isHighlighted = true;
            const cellValue = row.values[newColumnIndex] as number | undefined;

            // cellElement typing is wrong here, it may actually be an array-like object ({ 0: ..., 1: ..., length: 2})
            const realElement: HTMLElement = (cellElement as any)?.[0] ?? cellElement;

            if (realElement != null && dataField != null) {
                setFocusedCell({ rowKey: row.key, value: cellValue, dataField, component }, realElement);
            }
        },
        [setFocusedCell],
    );

    return updateFocusedCell;
}

export default useFocusedTableCell;

import { Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../translations/main-translations";

interface CalculationTableLockedCellProps {
    data: any;
}

const CalculationTableLockedCell = ({ data }: CalculationTableLockedCellProps) => {
    const { t } = useTranslation();

    return (
        <Tooltip
            title={t(`${translationKeys.VDLANG_CALCULATION_TABLE_TOOLTIP}.calculation_locked`)}
            // Disable transition, which causes flickering when moving mouse during transition
            TransitionProps={{ timeout: 0 }}
        >
            {data.text}
        </Tooltip>
    );
};

export default React.memo(CalculationTableLockedCell);

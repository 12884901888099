import { EffectFilterCurrencyField, GlobalCalculationIdentifier } from "api-shared";
import { TFunction } from "i18next";
import React, { ReactNode } from "react";

const CellContext = React.createContext<ICellContext | undefined>(undefined);

/**
 * Makes all of its props available via context to the whole subtree. Their values can be accesses using the useCellContext hook.
 * This is useful to pass dependencies from the Calculation table component to the table cells.
 *
 * @param {ICellContextProviderProps} {
 *     translate,
 *     onEditCategory,
 *     onRemoveCategory,
 *     onOpenHistory,
 *     onAddTimeslice,
 *     onSaveColumns,
 *     onCopyLevel,
 *     getIsFieldVisible,
 *     children,
 * }
 * @returns
 */
export const CellContextProvider = ({
    translate,
    onEditCategory,
    onRemoveCategory,
    onOpenHistory,
    onAddTimeslice,
    onSaveColumns,
    onCopyLevel,
    getIsFieldVisible,
    children,
}: ICellContextProviderProps) => {
    const value = React.useMemo(
        () => ({
            translate,
            onEditCategory,
            onRemoveCategory,
            onOpenHistory,
            onAddTimeslice,
            onSaveColumns,
            onCopyLevel,
            getIsFieldVisible,
        }),
        [translate, onEditCategory, onRemoveCategory, onOpenHistory, onAddTimeslice, onSaveColumns, onCopyLevel, getIsFieldVisible],
    );
    return <CellContext.Provider value={value}>{children}</CellContext.Provider>;
};

interface ICellContextProviderProps extends ICellContext {
    children: ReactNode;
}

interface ICellContext {
    translate: TFunction;
    onEditCategory: (categoryId: number) => void;
    onRemoveCategory: (categoryId: number) => void;
    onOpenHistory: (categoryId: number) => void;
    onAddTimeslice: (location: "top" | "bottom") => void;
    onSaveColumns: (
        effectCategoryId: number,
        calculationIdentifier: GlobalCalculationIdentifier,
        newHasInitial: boolean,
        newPriceHike: boolean,
    ) => void;
    onCopyLevel: (effectCategoryId: number, calculationIdentifier: GlobalCalculationIdentifier) => void;
    getIsFieldVisible: (
        effectCategoryId: number,
        calculationIdentifier: GlobalCalculationIdentifier,
        type: EffectFilterCurrencyField,
    ) => boolean;
}

/**
 * Hook for easy access to the context's value. Throws an error if not used inside the context.
 *
 * @returns {ICellContext}
 */
export const useCellContext = (): ICellContext => {
    const context = React.useContext(CellContext);
    if (context === undefined) {
        throw new Error("Cell context not defined");
    }
    return context;
};
